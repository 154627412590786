
import { defineComponent } from "vue";
import HeaderHome from "@/components/HeaderHome.vue";
import HeroSuccess from "@/components/HeroSuccess.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "VotingSuccess",
  components: {
    HeaderHome,
    HeroSuccess,
    Footer,
  },
  mounted() {
    let body = document.body;
    body.classList.add("white");
  },
});
