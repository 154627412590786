<template>
  <!-- Main Content -->
  <main class="hero">
    <div class="main__content">
      <h1>Tack för din röst</h1>
      <h2>
        <div>&#128155; &#128155; &#128155;</div>
      </h2>
      <h3>Följ oss gärna på Instagram och TikTok</h3>
      <h4>
        <a href="http://www.instagram.com/heyou.sverige/">
          <i class="fab fa-instagram"></i>@heyou.sverige</a
        >
      </h4>
      <h4>
        <a href="https://www.tiktok.com/@heyousverige">
          <i class="fab fa-tiktok"></i>@heyousverige</a
        >
      </h4>
      <h4>
        <a rel="Skicka in en ny nominering" href="/"
          ><i class="fas fa-caret-left"></i>Skicka in en till röst</a
        >
      </h4>
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "HeroSuccess",
  data() {
    return {};
  },
  props: {},
  methods: {},
});
</script>

<style lang="scss" scoped>
h4:last-child {
  margin-top: 1rem;
}
</style>
